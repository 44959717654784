<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Balance the following molecular equations. Write balanced ionic and net ionic equations for
        the reactions including the physical state ((s), (aq), etc).
      </p>

      <p class="mb-2">
        Molecular:
        <calculation-input
          v-model="inputs.input1"
          class="mr-1"
          style="max-width: 50px; display: inline-block"
        />
        <chemical-latex content="Ba(OH)2(aq)" />
        +
        <calculation-input
          v-model="inputs.input2"
          class="mr-1"
          style="max-width: 50px; display: inline-block"
        />
        <chemical-latex content="K2CrO4(aq)" />
        <stemble-latex content="$\rightarrow$" />
        <calculation-input
          v-model="inputs.input3"
          class="mr-1"
          style="max-width: 50px; display: inline-block"
        />
        <chemical-latex content="BaCrO4(s)" />
        +
        <calculation-input
          v-model="inputs.input4"
          class="mr-1"
          style="max-width: 50px; display: inline-block"
        />
        <chemical-latex content="KOH(aq)" />
      </p>

      <p class="mb-2">
        Ionic:
        <chemical-notation-input
          v-model="inputs.input5"
          style="display: inline-block"
          dense
          :show-note="false"
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-2">
        Net Ionic:
        <chemical-notation-input
          v-model="inputs.input6"
          style="display: inline-block"
          dense
          :show-note="false"
          :disabled="!allowEditing"
        />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput.vue';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1210LPrelabEquations3',
  components: {
    ChemicalNotationInput,
    StembleLatex,
    CalculationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
    };
  },
};
</script>
